<template>
    <custom-header description="Looks like this page doesn't exist (yet?)" title="Error 404"/>
</template>

<script>
import CustomHeader from "@/components/templates/CustomHeader";

export default {
    name: "Error404",
    components: {
        CustomHeader
    },
    metaInfo: {
        title: 'Page not found'
    }
}
</script>

<style scoped>

</style>